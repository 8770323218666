<script>
export default {
  name: "DashComponent"
}
</script>

<template>
  <div>
    asdasdasdssdas
  </div>
</template>

<style scoped>

</style>