<template>
    <!-- <Carousel :itemsToShow="3.95" :wrapAround="true" :transition="500">
        <Slide v-for="slide in 10" :key="slide">
            <div class="carousel__item">
                <img class="rounded-circle w-50"
                     src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAK80nyUvD3O-2NYEbRYLi0imoXL7sK2lJxFil_lHp_gtKRNgGt_ebXqQOmyTehdBGZGE&usqp=CAU"
                     alt="">
            </div>
        </Slide>

        <template #addons>
            <Navigation/>
        </template>
    </Carousel> -->
    <div>

    </div>
</template>
<script>

// import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default {
    name: 'CarouselEmpresas',
    components: {
        // Carousel,
        // Slide,
        // Navigation,
    },

}
</script>

<style>
.carousel-card-mask {
    background: none !important;
}
</style>

