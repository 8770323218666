<template>
    <div>
        <center>
            <h1>404 not found</h1>
            <h2>it seems you're in the wrong page</h2>
            <router-link to="/">Home</router-link>
        </center>
    </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>
