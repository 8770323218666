<template>
    <div class="row justify-content-md-center form-container mt-3 pt-10 pb-10" id="orcamento">
        <div class=" d-flex justify-center align-center">
            <h1 class="text-center bg-blue-accent-1 w-50 mb-5 p-1 rounded-1 text-white">Contate-nos</h1>
        </div>

        <div v-if="codeResponse === 200" class="alert alert-success text-center fixed-top w-100 p-2 m-2" role="alert">
            Obrigado, logo entraremos em contato!
        </div>

        <div v-if="erroApi && messageApi.length !== 0" class="alert alert-danger text-center fixed-top w-100 p-2 m-2" role="alert">
            {{ messageApi }}
        </div>

        <div v-if="errorOnSubmit && !erroApi" class="alert alert-danger text-center" role="alert">
            Preencha todos os campos de forma válida!
        </div>
        <div class="d-flex gap-2 p-2 justify-center align-center"
            :class="{ 'flex-column': $vuetify.display.sm || $vuetify.display.xs }">

            <IconsForm :icon_call="icon_call" :icon_location="icon_location" :icon_sms="icon_sms" style="width: auto;" />

            <div v-if="!$vuetify.display.sm" class="col col-md-1 line bg-black h-100 mr-5"></div>

            <div class="col col-md-6" :class="{ 'col-md-12': $vuetify.display.sm }">

                <div class="row g-3 d-flex">

                    <div class="col-md-12 form-floating">

                        <input type="text" class="form-control" id="inputNome"
                            :class="{ 'is-valid': nomeValido, 'is-invalid': errorOnSubmit || nomeInvalido && nomeTocado }"
                            v-model="nome" @input="nomeTocado = true">
                        <label for="inputNome">Nome</label>

                        <div class="invalid-feedback" v-if="errorOnSubmit || nomeTocado && nomeInvalido">
                            O nome deve ter pelo menos 2 caracteres e não deve conter números.
                        </div>

                    </div>

                    <div class="col-md-12 form-floating">

                        <input type="email" class="form-control" id="inputEmail"
                            :class="{ 'is-valid': emailValido, 'is-invalid': errorOnSubmit || emailInvalido && emailTocado }"
                            v-model="email" @input="emailTocado = true">
                        <label for="inputEmail">Email</label>

                        <div class="invalid-feedback" v-if="errorOnSubmit || emailTocado && emailInvalido">
                            Por favor, digite um endereço de email válido.
                        </div>
                    </div>

                    <div class="col-md-12  mb-3  form-floating">
                        <input type="tel" inputmode="tel" class="form-control" id="inputTelefone" v-mask="'(##) #####-####'"
                            :class="{ 'is-valid': telefoneValido, 'is-invalid': errorOnSubmit || telefoneInvalido && telefoneTocado }"
                            v-model="telefone" @input="(telefoneTocado = true)">

                        <label for="inputTelefone" class="form-label">Telefone</label>


                        <div class="invalid-feedback" v-if="errorOnSubmit || telefoneTocado && telefoneInvalido">
                            Por favor, digite um número de telefone válido (formato: (xx) xxxxx-xxxx).
                        </div>
                    </div>

                    <div class="col-md-12  mb-3  form-floating">
                        <textarea rows="5" class="form-control" id="descricao"
                            :class="{ 'is-valid': descricaoValida, 'is-invalid': errorOnSubmit || !descricaoValida && descricaoTocado }"
                            v-model="descricao" @input="(descricaoTocado = true)"></textarea>

                        <label for="descricao" class="form-label">Descrição</label>

                        <div class="invalid-feedback" v-if="errorOnSubmit || !descricaoValida && descricaoTocado">
                            Por favor, digite pelo menos 5 caracteres.
                        </div>
                    </div>

                    <div class="col-12 d-flex justify-center align-center">
                        <v-btn :disabled="errorOnSubmit === false" :loading="errorOnSubmit === true"
                            class="btn btn-primary text-none mb-4"
                            :class="{ 'btn-danger': errorOnSubmit, 'btn-success': errorOnSubmit === false, 'btn-light': this.errorOnSubmit || this.errorOnSubmit === false }"
                            color="indigo-darken-3" size="default" variant="flat" @click="submit()">
                            Enviar
                          <div v-if="errorOnSubmit === false" class="ml-2 spinner-border text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script src="@/components/FormComponent.js"></script>

<style scoped>
.form-container {
    background: var(--tertiary-gray);
}

.line {
    width: 1px;
}
</style>