<template>
    <section class="py-5">
        <div class="container my-5">
            <div class="row justify-content-center">
                <div class="col-lg-6">
                    <h2 class="text-center">Sobre nós</h2>
                    <div class="text-center">
                        <p class="lead">Somos um grupo que desde 2008 vem prestando serviços de qualidade, pautado no
                            respeito e dedicação aos nossos clientes. Temos vasta experiência no ramo de móveis planejados e
                            buscamos atualizar e aperfeiçoar nossos trabalhos constantemente, acompanhando a tendência do
                            mercado.</p>

                    </div>

                    <div class="mt-3 text-center">
                        <p class="fs-5 ">
                            A Soluções Planejadas é também especializada no design e produção de móveis escolares. Nós
                            sabemos que vocês acompanham as tendências da evolução do sistema educacional e perspectivas
                            pedagógicas!
                        </p>
                        <p class="fs-5">
                            É por isso que queremos mostrar nosso total compromisso e responsabilidade para atender as suas
                            demandas relacionadas a Marcenaria Escolar. Para nós o que realmente importa é proporcionar uma
                            ampla infraestrutura baseada na compreensão dos objetivos de cada uma das Instituições de
                            Ensino.
                        </p>
                        <p class="fs-5">
                            O nosso conceito de prestação de serviços tem como base, produzir com eficiência, beleza e
                            dedicação, cada detalhe que toda escola necessita:
                        </p>
                        <p class="fs-5">
                            Acessibilidade, segurança, durabilidade e toda documentação necessária para sua prestação de
                            contas.
                        </p>
                        <p class="fs-5">
                            Utilizamos material MDF para confecção de seus produtos, com ferragens, suportes e rodízios de
                            qualidade e resistência, compreendendo a necessidade do seu uso diário.
                        </p>

                    </div>
                    <div class="d-flex justify-content-end mt-2">
                        <div>
                            <p class="mb-0">Responsável pela Soluções planejadas
                            </p>
                            <div class="d-flex justify-content-end">
                                <p>
                                    <strong>- Fernando Firmino Fraga.</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'SobreNos'
}
</script>

<style scoped></style>